[data-amplify-authenticator] [data-amplify-container] {
    min-width: 350px;
}
[data-amplify-authenticator] [data-amplify-router] {
    border-style: none;
    box-shadow: none;
    background-color: transparent;
}

.amplify-tabs {
    border-style: none;
    padding-left: 32px;
    padding-right: 32px;
}
.amplify-tabs-item {
    color: #000;
    font-weight: normal;
    border-style: none;
    border-radius: 30px;
    border-width: 0;
    font-size: 14px;
}
.amplify-tabs-item[data-state="active"] {
    color: #000;
    background-color: #d0d0d0;
}
[data-amplify-authenticator] [data-state="inactive"] {
    background-color: transparent;
}
.amplify-divider--label::after {
    background-color: inherit;
}

.amplify-divider--horizontal {
    border-bottom-width: 0;
}

button.amplify-button {
    border-radius: 30px;
    border-style: none;
    font-size: 14px;
}
.amplify-button--primary {
    box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%),
        0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%);
}
.amplify-button--primary:hover {
    background-color: #eb6e20;
}
.federated-sign-in-button {
    background-color: #fff;
    box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%),
        0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%);
}
.amplify-field__show-password {
    background-color: #fff;
    box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%),
        0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%);
}
.amplify-input {
    border-style: none;
    border-radius: 30px;
    background-color: #fff;
    font-size: 14px;
    box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%),
        0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%);
}
.amplify-field-group__control {
    border: none;
}
.amplify-input:focus {
    border: none;
    border-color: #fff;
    outline: 0;
}

.amplify-textarea:focus {
    border: none;
    border-color: #fff;
    outline: 0;
}
.amplify-heading--3 {
    font-size: 14px;
}
.amplify-heading--4 {
    font-size: 14px;
}
.amplify-button--link:hover {
    background-color: var(--amplify-components-button-link-background-color);
}
